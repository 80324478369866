/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h2: "h2",
    a: "a",
    div: "div",
    p: "p",
    strong: "strong",
    h3: "h3"
  }, _provideComponents(), props.components), {HrefLangManager, TableOfContents, SideBySide, LandingPageCta} = _components;
  if (!HrefLangManager) _missingMdxReference("HrefLangManager", true);
  if (!LandingPageCta) _missingMdxReference("LandingPageCta", true);
  if (!SideBySide) _missingMdxReference("SideBySide", true);
  if (!TableOfContents) _missingMdxReference("TableOfContents", true);
  return React.createElement(React.Fragment, null, React.createElement(HrefLangManager, {
    alternates: [['x-default', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en-US', 'https://www.hear.com/hearing-loss/high-frequency/'], ['en-CA', 'https://ca.hear.com/hearing-loss/high-frequency/']]
  }), "\n", React.createElement(TableOfContents, {
    tableOfContents: props.data.mdx.tableOfContents
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "high-frequency-hearing-loss-affects-your-ability-to-understand-speech",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#high-frequency-hearing-loss-affects-your-ability-to-understand-speech",
    "aria-label": "high frequency hearing loss affects your ability to understand speech permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "High-frequency hearing loss affects your ability to understand speech"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ears perceive many sounds – especially consonants or hissing sounds such as “s”, “f” and “sh” – in the high-frequency range. In contrast, the vowels a, i, e, u and o are within the low frequency range and are less important for speech intelligibility; they sound muffled and convey the volume of speech instead."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Definition of high-frequency loss:"), " people who are experiencing high-frequency hearing loss (“s”, “f” and “sh” sounds) are unable to clearly hear the person with whom they are speaking, even at a normal volume. High-frequency hearing loss limits speech intelligibility, even though perception of volume is normal (isolated high-frequency hearing loss). The good news is, hearing aids can do a wonderful job of compensating for this type of ", React.createElement(_components.a, {
    href: "/hearing-loss/",
    className: "c-md-a"
  }, "hearing loss"), "."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-causes-high-frequency-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-causes-high-frequency-hearing-loss",
    "aria-label": "what causes high frequency hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What causes high-frequency hearing loss?"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "The causes for this type of hearing loss are numerous. However, progressive age and/or sensory hair cells in the inner ear, damaged by noise, are mostly responsible for the loss in high-frequency sounds. So, in this case, one could talk about “noise-induced hearing loss,” as it is primarily loud noises that lead to this kind of damage."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "In addition to being exposed to age and sensory hair damage, listening to your favorite music too loud or going to ", React.createElement(_components.a, {
    href: "/resources/hearing-aid-success/festival-season/",
    className: "c-md-a"
  }, "one rock concert"), " too many without ear protection can lead to high-frequency hearing loss. This is also one of the reasons why there is an increasing number of young people with poor hearing. You can actively protect your hearing by using ear muffs or in-ear protection when you find yourself in a noisy surrounding."), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "what-are-the-symptoms-of-high-frequency-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#what-are-the-symptoms-of-high-frequency-hearing-loss",
    "aria-label": "what are the symptoms of high frequency hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "What are the symptoms of high-frequency hearing loss?"), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearling-loss-visualized.jpg"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "As mentioned before, hearing loss of this kind means that speech intelligibility is limited. The perception of volume may be normal, but you might feel like the people around you are mumbling. Therefore, one common symptom is that you can hear but can’t really understand what someone is saying. In addition, if high-frequency hearing loss is present, you might experience some of the following symptoms: ringing in the ears, social isolation, and difficulty understanding female and children’s voices. Usually this type of hearing loss is self-diagnosable, but you should consult a hearing care professional for medical advice.")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "When it comes to hearing loss, you need the support and guidance of experienced professionals. The hear.com approach is like no other hearing care company. With us, you will not only get compassionate and quality care, but also top-of-the-line hearing aids at a competitive price. We guarantee that you’ll be happy with our service, your hearing aids, and your improved quality of life. Sign up today for a 45 day risk-free trial!"), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(_components.h2, {
    className: "c-md-h2",
    id: "degrees-of-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#degrees-of-hearing-loss",
    "aria-label": "degrees of hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Degrees of hearing loss"), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Hearing healthcare professionals characterize hearing in four different degrees: Mild, moderate, severe and profound."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Mild hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "If you can have one-on-one conversation but have a hard time understanding certain words when lots of background noise is present, you may be experiencing mild hearing loss. In addition, you may find yourself constantly raising the volume of your TV or radio."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Moderate hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "An indicator that you may be experiencing moderate hearing loss is trouble hearing during group conversations, especially in environments with a lot of background noise. As a result, you may find yourself asking people to repeat themselves or reading lips to understand. Additionally, deep male voices are understood better than high-pitched female or children’s voices."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.strong, null, "Severe hearing loss")), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Like moderate hearing loss, severe hearing loss makes it difficult for you to understand speech. Because you have to concentrate really hard to understand, you end up feeling fatigued after a conversation. If severe hearing loss is present, you might feel less energetic throughout the day."), "\n", React.createElement(_components.p, {
    className: "c-md-p"
  }, "Profound hearing loss\nIf you have profound hearing loss, you rely mostly on lip-reading and/or sign language to understand. At this point, speech intelligibility is severely limited."), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/signia-mini.png"
  }, React.createElement(_components.p, {
    className: "c-md-p"
  }, "Depending on the severity of your hearing loss, treatment options are available. For profound hearing loss, a cochlear implant may help. In most cases of high-frequency hearing loss, hearing aids are an excellent solution. Both ", React.createElement(_components.a, {
    href: "/hearing-aids/types/bte/",
    className: "c-md-a"
  }, "behind-the-ear"), " and ", React.createElement(_components.a, {
    href: "/hearing-aids/types/ite/",
    className: "c-md-a"
  }, "in-the-ear devices"), " deliver crystal-clear sound and automatic features that reduce background noise. To determine the proper course of action, you can speak to one of our experts over the phone. Our consultation is free and with no obligations. We can tell you more about the latest hearing aids and how they can help improve your quality of life. We even offer a 45 day risk-free trial so you can test some of the best devices on the market.")), "\n", React.createElement(LandingPageCta, {
    copy: "Start No-risk Trial",
    classes: "c-cta--content"
  }), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-audiogram.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "high-frequency-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#high-frequency-hearing-loss",
    "aria-label": "high frequency hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "High-frequency hearing loss"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Our ears perceive many sounds – especially consonants or hissing sounds such as “s”, “f” and “sh” – in the high-frequency range. In contrast, the vowels a, i, e, u and o are within the low frequency range and are less important for speech intelligibility; they sound muffled and convey the volume of speech instead.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-ear.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "cookie-bite-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#cookie-bite-hearing-loss",
    "aria-label": "cookie bite hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Cookie bite hearing loss"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "There are many ways someone can lose their hearing. For example, hearing loss can be age-related, hereditary or caused by environmental factors. There is also a less common type of hearing loss called ", React.createElement(_components.a, {
    href: "/hearing-loss/cookie-bite/",
    className: "c-md-a"
  }, "cookie bite hearing loss"), ". Cookie bite hearing loss is unusual because it affects certain sounds someone will hear. It develops slowly and is harder to detect than other forms of hearing loss.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-old-age-tinnitus.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "combined-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#combined-hearing-loss",
    "aria-label": "combined hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Combined Hearing Loss"), React.createElement(_components.p, {
    className: "c-md-p"
  }, "Combined hearing loss describes the occurrence of conductive hearing loss that also has a sensorineural component. The adverse effects result from the component that is more pronounced.")), "\n", React.createElement(SideBySide, {
    imgSrc: "https://res.cloudinary.com/dhqvlsb3l/image/upload/f_auto,q_auto:best/v1/assets/na/seo-website/hearing-loss-resound-linx.jpg"
  }, React.createElement(_components.h3, {
    className: "c-md-h3",
    id: "sensorineural-hearing-loss",
    style: {
      position: "relative"
    }
  }, React.createElement(_components.a, {
    href: "#sensorineural-hearing-loss",
    "aria-label": "sensorineural hearing loss permalink",
    className: "anchor before"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  })), "Sensorineural hearing loss"), React.createElement(_components.p, {
    className: "c-md-p"
  }, React.createElement(_components.a, {
    href: "/hearing-loss/sensorineural/",
    className: "c-md-a"
  }, "Sensorineural hearing loss"), " (also called Labyrinthine hearing loss) is a functional impairment of the hearing organ (sound-sensing hair cells) as well as a dysfunctional neural component which can lead to hearing impairment (hypoacusis). Its severity may range from slight comprehension difficulties to complete hearing loss or deafness. The early provision of hearing aids helps relieve these symptoms and improve hearing capacity.")));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
